import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Menu } from './common/Menu';
import HomeContainer from "./pages/Home";
import AboutContainer from "./pages/About";
import ServicesContainer from "./pages/Services";
import GamesStudioContainer from "./pages/GamesStudio";
import ContactContainer from "./pages/Contact";
import PrivacyPolicyContainer from "./pages/PrivacyPolicy";
import Footer from "./common/Footer";
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Menu />
      </header>
      <BrowserRouter>
        <Routes>
          <Route exact path={"/"} element={<HomeContainer />} />
          <Route path={"/about"} element={<AboutContainer />} />
          <Route path={"/services"} element={<ServicesContainer />} />
          <Route path={"/games"} element={<GamesStudioContainer />} />
          <Route path={"/contact"} element={<ContactContainer />} />
          <Route path={"/privacy-policy"} element={<PrivacyPolicyContainer />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
