import React from "react";
import Container from 'react-bootstrap/Container';

const ServicesContainer = () => {
    return (
        <Container className={"services-page-container"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"services-page-header-container animate__animated animate__fadeInRightBig"}>
                        <h1 className={"services-page-header"}>
                            <strong>
                                SERVICE <br />& SECTORS
                            </strong>
                        </h1>
                    </div>

                    <div className={"services-page-subheader-container"}>
                        <h4 className={"services-page-subheader"}>
                            <strong>
                                WE OFFER A PLATFORM GAMES AGGREGATOR AND GAME DEVELOPMENT AND A B2B SERVICE FOR THE ONLINE GAMING INDUSTRY.
                            </strong>
                        </h4>
                    </div>

                    <div className={"services-page-body"}>
                        <div className={"services-page-service"}>
                            <h4 className={"services-page-service-subheader"}>
                                <strong>
                                    AGGREGATOR
                                </strong>
                            </h4>
                            <p>
                                We offer not only our own games but also great titles from our game provider partners. New games and studios are constantly being updated on our end to ensure you have a great selection to choose from. We also handle all of the integration so you can get access to these great games as quick and easy as possible.
                            </p>
                            <p>
                                Being able to handle our own integration allows us full control in the “behind the scenes” work, meaning not only are you guaranteed the best service from our side, but you view the best service from yours.
                            </p>
                            <p>
                                This is just another small step we take in ensuring your experience with us is worthwhile and meets the highest of expectations.
                            </p>
                        </div>

                        <div className={"services-page-service"}>
                            <h4 className={"services-page-service-subheader"}>
                                <strong>
                                    PLATFORM
                                </strong>
                            </h4>
                            <p>
                                At United Remote we like to make sure our partners have the best tools to ensure they can communicate with their customers as easily and simply as possible. We have a selection of marketing tools available and are constantly expanding our available range.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ServicesContainer;
