import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import slidesArray from "../games-studio/slides.json";
import '@splidejs/react-splide/css';

const Slider = ({slidesArray}) => {
    const [slides, setSlides] = useState(slidesArray);

    let slidesElements = [];

    slides.forEach(slide => {
        const { title, description, details, image } = slide;

        slidesElements.push(
            <SplideSlide>
                <div className={"slide-container"}>
                    <div className={"row"}>
                        <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                            <div className={"slide-wrapper"}>
                                <div className={"slide-header-container"}>
                                    <h1 className={"slide-header"}>{title}</h1>
                                </div>
                                <div className={"slide-description-container"}>
                                    <h4 className={"slide-description"} dangerouslySetInnerHTML={{__html: description}}></h4>
                                </div>
                                <div className={"slide-details-container"}>
                                    <p className={"slide-details"} dangerouslySetInnerHTML={{__html: details}}></p>
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                            <img className={"slide-image img-fluid"} src={image} alt={title} />
                        </div>
                    </div>
                </div>
            </SplideSlide>
        );
    });

    return slidesElements;
}

const GamesStudioContainer = () => {
    const options = {
        rewind: true,
        type: 'loop',
        width: '100%',
        height: 'auto',
        perPage: 1,
        perMove: 1,
        arrows: true,
        autoplay: true,
        interval: 10000,
        pagination: false,
        start: 0,
        drag: true,
        padding: 0,
        margin: 0
    };

    return (
        <Container className={"games-studio-page-container"}>
            <Splide aria-label="United Remote game studio" tag={"section"} options={options}>
                <Slider slidesArray={slidesArray} />
            </Splide>
        </Container>
    );
}

export default GamesStudioContainer;
