import React from "react";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { Button } from "react-bootstrap";

const ContactContainer = () => {
    return (
        <Container className={"contact-page-container"}>
            <div className={"row"}>
                <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                    <div className={"contact-page-form-container"}>
                        <h1 className={"contact-page-header"}>
                            <strong>
                                Contact Us
                            </strong>
                        </h1>
                        <div className={"contact-page-body"}>
                            <p>Should you require any further information do not hesitate to contact us</p>
                            <a href={"mailto:info@united-remote.com"}>
                                <Button className={"contact-form-button btn-lg animate__animated animate__rollIn"} >Send Email</Button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                    <div className={"contact-page-image-container"}>
                        <img className={"contact-page-image img-fluid"} src={"../images/contact.png"} alt={"customer support at work"} />
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ContactContainer;


// for when a contact form is implemented component ready to replace current
// const handleSubmit = (e) => {
//     e.preventDefault();
//     const { name, email, message} = e.target.elements;
// }

// <Container className={"contact-page-container"}>
//     <div className={"row"}>
//         <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
//             <div className={"contact-page-form-container"}>
//                 <h1 className={"contact-page-header"}>Contact Us</h1>
//                 <Form onSubmit={handleSubmit}>
//                     <Form.Control name={"name"} className={"mb-3"} type={"text"} placeholder={"Name"} />
//                     <Form.Control name={"email"} className={"mb-3"} type={"email"} placeholder={"Email"} />
//                     <Form.Control name={"message"} as={"textarea"} placeholder={"Message"} rows={"5"} />
//                     <Button className={"contact-form-button btn-lg mt-4"} type={"submit"}>Send</Button>
//                 </Form>
//             </div>
//         </div>
//         <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
//             <div className={"contact-page-image-container"}>
//                 <img className={"contact-page-image img-fluid"} src={image} />
//             </div>
//         </div>
//     </div>
// </Container>
