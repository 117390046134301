import React from "react";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import "animate.css";

const HomeContainer = () => {
    const options = {
        rewind: true,
        type: 'loop',
        width: '100%',
        height: '100%',
        perPage: 1,
        perMove: 1,
        arrows: true,
        autoplay: true,
        interval: 10000,
        pagination: false,
        start: 1,
        drag: true,
        padding: 0,
        gap: '1rem',
    };

    return (
        <Container className={"page-container"}>
            <Splide aria-label="United Remote game studio" tag={"section"} options={options}>
                <SplideSlide>
                    <div className={"intro-slide-1"}>
                        <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                            <div className={"intro"}>
                                <div className={"intro-header"}>
                                    <h1>United Remote Studio</h1>
                                </div>
                                <div className={"intro-text"}>
                                    <h4>
                                        <strong>
                                            United Remote is a platform provider, white label solution and innovative game studio.
                                        </strong>
                                    </h4>
                                    <p>We create state of the art casino games with passion and precision and our aim is to provide accurate, fun and user friendly products to the gaming industry.</p>
                                    <p>We offer easy integration and flexibility. We strive to ensure that businesses achieve their goals.</p>
                                </div>
                            </div>
                        </div>

                        <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                            <div className={"intro-image"}>
                                <img src={"../images/UR_banner_animation.gif"} alt={"Spinning roulette"} />
                            </div>
                        </div>
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <div className={"intro-slide-2"}>
                        <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                            <div className={"intro-2"}>
                                <div className={"intro-header-2"}>
                                    <h1>Welcome to United Remote</h1>
                                </div>

                                <div className={"intro-subheader-2"}>
                                    <h2>
                                        <strong>
                                            A modern, innovative platform, game aggregator and game development studio.
                                        </strong>
                                    </h2>
                                </div>

                                <div className={"intro-text-2"}>
                                    <p>We make sure that all our products are to the highest standards by testing them with care, so they fulfil the demands of both players and operators. </p>
                                </div>
                            </div>
                        </div>

                        <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                            <div className={"intro-image-2"}>
                                <img src={"../images/team.jpg"} alt={"Team at work"} />
                            </div>
                        </div>
                    </div>
                </SplideSlide>
            </Splide>

            <div className={"read-more"}>
                <Link to={"/services"}>
                    <button className={'read-more-button btn btn-lg text-white animate__animated animate__rollIn'}>Read more</button>
                </Link>
            </div>
        </Container>
    )
}

export default HomeContainer;
