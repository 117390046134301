import React from "react";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

const PrivacyPolicyContainer = () => {
    return (
        <Container className={"privacy-policy-page-container"} >
            <Row>
                <Col>
                    <div className={"privacy-policy-page-wrapper"}>
                        <div className={"privacy-policy-page-header-wrapper"}>
                            <h1 className={"privacy-policy-page-header animate__animated animate__fadeInRightBig"}>
                                <strong>
                                    Privacy Policy
                                </strong>
                            </h1>
                        </div>

                        <h4>
                            <strong>
                                A. General
                            </strong>
                        </h4>

                        <p>This Privacy Policy applies to the website www.united-remote.com.</p>

                        <p>This Privacy Policy will inform you on how we look after your personal data when you submit your relevant information through the dedicated section ‘work with us’ on this website and informs you on how your data is processed, your privacy rights and how the law protects you.</p>

                        <p>This website is not intended for children and we do not knowingly collect data relating to children.</p>

                        <p>The entity responsible for this website for the purposes of data protection law is United Remote Ltd., with company registration number C66437, having its registered address at United Remote, Forth Business Centre, Level 1, Suite 3, Testaferrata Street, Ta`Xbiex, Malta (the <strong>“Controller”</strong>).</p>

                        <p>Any reference in this Privacy Policy to <strong>“United Remote Ltd.”, “us”, “we”, “our”</strong>, or <strong>‘United Remote’</strong> is a reference to the Controller.</p>

                        <p>We refer to you, the visitor and/or the user of our services, as <strong>“you”</strong> and derivatives of the word <strong>“you”</strong>.</p>

                        <p>United Remote is concerned with protecting the privacy of any personal data we hold. We are committed to comply with the European General Data Protection Regulation 2016/679 (the <strong>“GDPR”</strong>).</p>

                        <p>If you have any questions about this Privacy Policy or our privacy practices, you may contact our Group Data Protection Officer (DPO):</p>

                        <p><strong>Email address</strong>: <a href={"mailto:dpo@united-remote.com"}>dpo@united-remote.com</a></p>

                        <p><strong>Postal address</strong>: Forth Business Centre, Level 1, Suite 3, Testaferrata Street, Ta`Xbiex, Malta</p>

                        <h4>
                            <strong>
                                B. Changes to the Privacy Policy
                            </strong>
                        </h4>

                        <p>We keep our Privacy Policy under regular review. This version was last updated in October 2020. Historic versions can be obtained by contacting us.</p>

                        <p>We reserve the right to amend this Privacy Policy from time to time. This Privacy Policy may be subject to changes.</p>

                        <h4>
                            <strong>
                                C. Personal Data
                            </strong>
                        </h4>

                        <p>Any information that either on its own or combined with any other data can be used to identify the you is considered as personal data.</p>

                        <h4>
                            <strong>
                                D. Processing
                            </strong>
                        </h4>

                        <p>Any set of operations performed by us on your personal data including, but not limited to, collecting, recording, organising, storing or consulting is considered as processing.</p>

                        <h4>
                            <strong>
                                E. Personal Data
                            </strong>
                        </h4>

                        <p>As a Controller, we process the the personal data of yours which you submit to us through the ‘Careers’ section or ‘Contact’ section as the case may be.</p>

                        <h4>
                            <strong>
                                F. Cookies
                            </strong>
                        </h4>

                        <p>A cookie is a small piece of data (small text file) sent from our website and stored on your computer or any device used by your browser while you are browsing the web.</p>

                        <p>Please note that we do not make use of any cookies on this website.</p>

                        <h4>
                            <strong>
                                G. Work with us
                            </strong>
                        </h4>

                        <p>When you apply for a job with us, we will ask you to provide us with personal information about yourself so we can evaluate your application.</p>

                        <p>If this information is not provided, our ability to consider you as a candidate may be limited if not practical.</p>

                        <p>You may also provide us with your personal information that we have not specifically requested (for example, your CV may contain information about your hobbies and social preferences).</p>

                        <p>All information is provided on a voluntarily basis and you determine the extent of information that you provide to us.</p>

                        <h6>
                            <strong>
                                Personal Information
                            </strong>
                        </h6>

                        <p>The kind of personal information we collect about you may include:</p>

                        <p>Name, identity and contact details – such as your name, address, email address, telephone, other contact information and personal preferences;</p>

                        <p>Employment details – such as details of your employment background, including position, work experience, employment references, salary and other compensation requests;</p>

                        <p>Background information– such as your educational background including degrees, certificates, transcripts, credit history and/or criminal background screenings (where relevant to your application and permitted under applicable law);</p>

                        <p>Financial Information – such as salary, tax information, benefits, expenses, company allowances, stock and equity grants;</p>

                        <p>Any other information in your application that you choose to share with us – such as personal preferences, hobbies, social preferences, etc.</p>

                        <h6>
                            <strong>
                                Sensitive Personal Information
                            </strong>
                        </h6>

                        <p>We do usually not request or require sensitive personal information in terms of Article 9 of the GDPR during the hiring process; nonetheless, should that be the case we will ask for your consent whilst giving you details over the purposes and legal grounds upon which the processing of sensitive personal information would be based on.</p>

                        <h6>
                            <strong>
                                Information from other third parties
                            </strong>
                        </h6>

                        <p>From time to time, we may obtain information about you from public sources or third parties. For example, we may conduct background screenings through a third-party service provider and verify information that you have in your application that relates to your past education, employment, credit and/or criminal history, as allowed by applicable law.</p>

                        <p>You may also choose to provide us with access to certain personal information stored by third parties such as job-related social media sites (for example, LinkedIn). By authorizing us to have access to this information, you agree that we may collect, store and use this information in accordance with this Privacy Policy.</p>

                        <p>We may also obtain and share information through our affiliated companies.</p>

                        <h6>
                            <strong>
                                Information sharing
                            </strong>
                        </h6>

                        <p>Your personal data may be shared internally for the purposes of the recruitment process triggered by the submission of the relevant application. This might involve as recipients the members of the HR and Recruitment team, interviewers involved in the recruitment process, managers in the business area with a vacancy, IT staff if access to the data is necessary for the performance of their roles or for security reasons.</p>

                        <p>Your personal information may be shared for the purposes described above with other companies of our group. A list of other companies within our group is available upon request by contacting dpo@united-remote.com.</p>

                        <p>If your application for employment is unsuccessful, we will hold your data on file for one year following the termination of the recruitment process.</p>

                        <p>Further information in this regard can be obtained by contacting <a href={"mailto:dpo@united-remote.com"}>dpo@united-remote.com.</a></p>

                        <h4>
                            <strong>
                                H. Data transfer to outside the EU or EEA
                            </strong>
                        </h4>

                        <p>No Personal Data is intended to be transferred to any country outside of the EU/EEA area.</p>

                        <h4>
                            <strong>
                                I. Security
                            </strong>
                        </h4>

                        <p>No method of transmission over the Internet, or method of electronic storage is 100% secure and we cannot ensure or warrant the security of the information you transmit to us or store on the server and that you are doing so at your own risk.</p>

                        <p>We also cannot guarantee that such information may not be accessed, disclosed, altered and or destroyed by breach being physical, technical or organisational.</p>

                        <p>That being said, we take all adequate technical and organisational efforts to protect your personal data from access by unauthorised persons and to prevent accidental or unlawful processing, disclosure, destruction, loss, alteration or damage.</p>

                        <p>All sensitive data which is transmitted between your computer and us is transferred securely via the internet, using strong encryption. We use secure and tested encryption technology bearing certificates provided by renowned certificate authorities.</p>

                        <p>In addition, all personal data will be protected from unauthorised or damage access by firewall and intrusion prevention systems.</p>

                        <p>Lastly, please note that whilst we do our part, you should also take your personal steps to safeguard the security of your data both physically and electronically by following common best practice procedures such as:</p>

                        <p className={"indent"}>(a) Running Anti-Virus software and keeping it up to date;</p>
                        <p className={"indent"}>(b) Applying operating systems, web browsers and other security updates;</p>
                        <p className={"indent"}>(c) Ensuring your laptop or other device is not left unattended whilst logged into our website;</p>
                        <p className={"indent"}>(d) Using strong passwords for all services/logins.</p>

                        <h4>
                            <strong>
                                J. Your rights
                            </strong>
                        </h4>

                        <p>As a data subject, you have the following rights:</p>

                        <p className={"indent"}>i) To access and obtain a copy of your data on request;</p>
                        <p className={"indent"}>ii) to require us to change incorrect or incomplete data;</p>
                        <p className={"indent"}>iii) to require us to delete or stop processing your data, for example where the data is no longer necessary for the purposes of processing;</p>
                        <p className={"indent"}>iv) to object to the processing of your data where we rely on our legitimate interests as the legal ground for processing; and</p>
                        <p className={"indent"}>v) receive the personal data you have provided to us in a structured, commonly used and machine-readable format.</p>

                        <p>If you are not happy with the way in which your personal data has been processed in connection with the use of this website, you are in the first instance encouraged to contact us by using the email address detailed above.</p>

                        <p>Should you remain dissatisfied with the measures for remedying the situation undertaken by us, you are able to file a complaint with the Office of the Information and Data Protection Commissioner at Malta for a decision in the matter.</p>

                        <p>The Office of the Information and Data Protection Commissioner can be contacted at:</p>

                        <p>Information and Data Protection Commissioner, Level 2, Airways House, High Street, Sliema SLM 1549, Malta</p>

                        <p>
                            <a href={"www.idpc.org.mt"}>
                                www.idpc.org.mt
                            </a>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default PrivacyPolicyContainer;
