import React from "react";
import Container from 'react-bootstrap/Container';

const AboutContainer = () => {
    return (
        <Container className={"about-page-container"}>
            <div className={"row"}>
                <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6 about-page-wrapper"}>
                    <div className={"about-page-slide"}>
                        <h1 className={"about-page-header"}>
                            <strong>
                                About
                            </strong>
                        </h1>
                        <p className={"about-page-paragraph"}>
                            United Remote is a modern, innovative platform and game development studio. We create state of the art products with passion and precision. Our aim is to provide accurate, fun and user friendly solutions to the gaming industry. We make sure that all our products are to the highest of standards by testing them with care, so they fulfil the demands of both players and operators. We Offer:
                        </p>
                        <ul className={"about-page-list"}>
                            <li>Platform Games Aggregator</li>
                            <li>Game Development</li>
                            <li>A B2B Service For The Online Gaming Industry</li>
                            <li>The highest quality services</li>
                        </ul>
                        <p className={"about-page-paragraph"}>
                            Our culture is driven around friendliness and the passion to provide the best we possibly can to others by listening, understanding and acting on feedback. We strongly believe in the foundations of users feedback, and our goals are steered forwards by this.
                        </p>
                    </div>
                </div>

                <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-6"}>
                    <img className={"about-page-image img-fluid"} src={"../images/about-page-image.webp"} alt={"At work"} />
                </div>
            </div>
        </Container>
    );
}

export default AboutContainer;
